import banner from './assets/banner.jpg';

const Header = () => {
  let prevScrollpos = window.pageYOffset;
  window.onscroll = function () {
    const currentScrollPos = window.pageYOffset;

    const NavBar = Array.from(document.getElementsByClassName('App-header'))[0];
    if (window.scrollY < 48) {
      NavBar.style.top = '0';
    } else if (prevScrollpos > currentScrollPos) {
      NavBar.style.top = '0';
    } else {
      NavBar.style.top = '-70px';
    }
    prevScrollpos = currentScrollPos;
  };

  return (
    <header>
      <div className='App-header'>
        <div className='header__nav'>
          <a href='#info' className='header__nav-link'>
            About
          </a>
          <a href='#testimonies' className='header__nav-link'>
            Testimonies
          </a>
          <a className='header__nav-link' href='#contact-form'>
            Contact
          </a>
        </div>
        <h1 className='header__title'>Ma'ayan Cohen</h1>
      </div>
      <div className={'header__image-container'}>
        <img src={banner} alt='healing hands' className={'header__image'} />
        <div className={'header__image-text'}>
          <div className={'title header__image-text__title'}>
            You're in good hands
          </div>
          <div className={'header__image-text__subtitle'}>
            Bring your body, mind, and spirit into balance through ayurvedic
            yoga massage
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
