export const testimonials = [
  {
    text: '“Ma’ayan was so professional, yet warm and kind - I immediately felt comfortable. The massage and deep stretching relaxed my body, and the atmosphere calmed me. Can’t wait for my next one!”',
    author: 'Rebecca M.',
    rating: 4.5,
  },
  {
    text: '“Thank you for the body and soul healing Maayan. My massage was professional and personalised for me. I found every step of the process easy and convenient. Before and after the massage, Maayan was communicative, friendly and patient. The massage left me feeling refreshed and relaxed and greatly aided in the healing of a recent injury.”',
    author: 'Gil R.',
    rating: 5,
  },
  {
    text: "“Ma’ayan's massage is so much more than the 'usual' kind that we know of, it's a lifting experience on a whole new, unexpected level. It wasn't just a great massage, it was a spiritual journey. When it ended, not only did I feel taller, calmer and more relaxed, physically and emotionally, but I also felt that I had experienced true magic.”",
    author: 'Shai G.',
    rating: 5,
  },
  {
    text: '“The massage was wonderfully relaxing. Maayan was attentive and sensitive and I was certainly in good hands! My body felt like warm jelly the next day.”',
    author: 'Zohar S.',
    rating: 5,
  },
  {
    text: "“This isn't a typical massage, it's a physical experience in which your entire body is engaged. I had some wrist pain that was much improved and I felt relaxed and more comfortable in my body afterwards.” ",
    author: 'Maiyse A.',
    rating: 4.5,
  },
  {
    text: "“I had a lovely experience with Ma'ayan. I felt so taken care of, she is very attentive and kind and I felt she was very present throughout the massage. I have had a lot of pain recently and the massage helped me and my body feels a lot lighter and less stiff. Amazing massage!”",
    author: 'Tash L.  ',
    rating: 5,
  },
  {
    text: "The sensation was both energizing and relaxing at the same time. I've never felt something like it before!",
    author: 'Dana G.',
    rating: 5,
  },
  {
    text: 'I walked into the session pretty overwhelmed and left with a straighter back, feeling calm and stretched, almost like I went to the gym. Ma’ayan is super professional, thoughtful and knowledgable about AYM!!  Thank you Ma’ayan!',
    author: 'Maya E.',
    rating: 4.5,
  },
  {
    text: "My session with Ma'ayan was really amazing and gave me a little reset. My body felt so lovely and loose for a few days after the session, which is testament to Ma'ayans ability to get the right pressure points. Recommend these treatments highly!",
    author: 'Jacob M.',
    rating: 4.5,
  },
];
