import React, { useState, useEffect } from 'react';
import { testimonials } from './constants';
import { Rating } from 'react-simple-star-rating';
import { useMediaQuery } from 'react-responsive';

function TestimonialScroller({ interval = 10000 }) {
  const [index, setIndex] = useState(0);
  const [isReverse, setIsReverse] = useState(false);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1000px)' });
  const translateXDistance = isTabletOrMobile ? 100 : 33;

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex((prevIndex) => {
        const testimonialsOnDisplay = isTabletOrMobile ? 1 : 3;
        if (prevIndex === testimonials.length - testimonialsOnDisplay) {
          setIsReverse(true);
        }
        if (prevIndex === 0) {
          setIsReverse(false);
        }
        const adjuster = isReverse ? -1 : 1;
        return (prevIndex + adjuster) % testimonials.length;
      });
    }, interval);

    return () => clearInterval(intervalId);
  });

  return (
    <div id='testimonies' className='testimonial-container'>
      <div
        className='testimonial-wrapper'
        style={{
          transform: `translateX(-${index * translateXDistance}%)`,
        }}
      >
        {testimonials.map((testimonial, idx) => (
          <div key={idx} className='testimonial'>
            <Rating
              readonly
              initialValue={testimonial.rating}
              allowFraction
              size={20}
              fillColor='black'
            />
            <p className='testimonial-text'>{testimonial.text}</p>
            <p className='testimonial-author'>- {testimonial.author}</p>
          </div>
        ))}
        {testimonials.map((testimonial, idx) => (
          <div key={idx + testimonials.length} className='testimonial'>
            <Rating
              readonly
              initialValue={testimonial.rating}
              allowFraction
              size={20}
              fillColor='black'
            />
            <p className='testimonial-text'>{testimonial.text}</p>
            <p className='testimonial-author'>- {testimonial.author}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default TestimonialScroller;
