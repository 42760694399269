import './App.css';
import Header from './Header';
import TestimonialScroller from './TestimonialScroller';
import ContactForm from './ContactForm';
import { useEffect } from 'react';
import emailjs from '@emailjs/browser';
import aboutMe from './assets/about_me_op_1.jpg';
import ImageBlock from './ImageBlock';

function App() {
  useEffect(() => {
    emailjs.init({
      publicKey: process.env.REACT_APP_PUBLIC_KEY,
      limitRate: {
        id: 'app',
        throttle: 10000,
      },
    });
  });

  return (
    <div className='App'>
      <Header />
      <section className={'about'}>
        <div className={'about__text'}>
          <h1 className={'about__text__title title'}> About Ma'ayan</h1>
          <div className={'about__text__subtitle subtitle'}>
            {' '}
            Your partner in healing
          </div>
          <div className={'about__text__body'}>
            Hi there, I’m Ma’ayan Cohen, a British-Israeli massage therapist
            based in Tel Aviv-Jaffa, and I am here to help bring your body into
            balance in a way that feels authentic and nurturing. As a certified
            Ayurvedic Yoga Massage Therapist, I’ve come to understand that true
            healing involves more than just the physical body - it's about
            tending to the mind and spirit as well. My approach to massage goes
            beyond the superficial, as I care deeply about creating a space
            where you can unwind, let go, and reconnect with your inner peace.
            <br />
            <br />
            So whether you are looking to release physical or emotional tension,
            improve your flexibility, or connect your mind and body, I’m here to
            walk alongside you (and sometimes, on you).
          </div>
        </div>
        <div>
          <img src={aboutMe} alt='about me' className={'about__img'} />
        </div>
      </section>
      <section className='info' id='info'>
        <div className='title'>What Is Ayurvedic Yoga Massage</div>
        <div className='subtitle'>
          Holistic healing from the comfort of your own home
        </div>
        <div>
          Ayurvedic Yoga Massage uses the rich Indian tradition of Ayurveda to
          help bring your body into balance. This 90-minute massage takes place
          in the comfort of your own home and combines deep tissue oil-based
          massage with assisted stretching and breathwork. Throughout the
          massage we use Calamus powder, an Ayurvedic herb that improves
          circulation, detoxifies the blood, and balances hormones.
          <br />
          <br />
          The massage is tailored to release tension, improve flexibility and
          posture, support blood circulation, and restore balance to both body
          and mind.
          <br />
          <br />
          Prior to the massage, we will have a consultation to best understand
          your body’s needs.
        </div>
        <a href='#contact-form' className='button'>
          Get Started
        </a>
      </section>
      <ImageBlock />
      <TestimonialScroller />
      <ContactForm />
    </div>
  );
}

export default App;
